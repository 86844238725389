<template>
    <div v-if="dropdownOpened" class="dropdown-list" :class="{ 'dropdown-list__match-width': matchWidth }">
        <div v-on-click-outside="() => emit('close')" class="dropdown-list__input-header">
            <h3>
                {{ mobilePopUpTitle }}
            </h3>
            <IconButton icon-name="ri:close-fill" aria-label="Close" @click.stop.prevent="emit('close')" />
        </div>
        <div class="dropdown-list__wrapper">
            <div class="dropdown-list-content">
                <template v-for="(item, index) in items">
                    <slot name="dropdown-item" v-bind="{ item, index }">
                        <button
                            id="GADropdownSelect"
                            :key="`${item.name}-${index}`"
                            :class="['dropdown-item', { 'dropdown-item--selected': item.name === modelValue }]"
                            type="button"
                            @click.stop.prevent="emit('update:modelValue', item)"
                        >
                            {{ item.name }}
                        </button>
                    </slot>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components';
import IconButton from '../Common/IconButton.vue';

export type DropdownSingleItem = {
    name: string;
    id: string | number;
};

export type DropdownItems = DropdownSingleItem[];

import { useIsMobileView } from '~ui/composables/isMobileView';
import screens from '#tailwind-config/theme/screens';

const { isMobileView } = useIsMobileView(screens.md);

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },

    items: {
        type: Array as PropType<DropdownItems>,
        required: true,
    },

    dropdownOpened: {
        type: Boolean,
        default: false,
    },

    mobilePopUpTitle: {
        type: String,
        required: false,
        default: '',
    },

    matchWidth: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['close', 'update:modelValue']);

useHead({
    bodyAttrs: {
        class: computed(() => {
            if (isMobileView.value && props.dropdownOpened) return 'body-overflow-hidden';
            return '';
        }),
    },
});
</script>

<style scoped lang="postcss">
.dropdown-list {
    @apply fixed bottom-0 left-0 w-screen;
    z-index: 200;

    &::before {
        @apply flex justify-center items-center fixed top-0 bottom-0 left-0 right-0 z-10;

        content: '';
        background-color: rgba(0, 0, 0, 0.25);

        @screen lg {
            @apply hidden;
        }
    }

    @screen lg {
        @apply absolute w-auto;

        left: 0;
        top: calc(100% + 0.5rem);
    }

    &.dropdown-list__match-width {
        @screen lg {
            @apply w-full;
        }
    }

    & .dropdown-list__wrapper {
        @apply bg-white border border-t-0 static box-content relative z-20;

        @screen lg {
            @apply border rounded-lg;
        }
    }

    & .dropdown-list-content {
        @apply overflow-y-scroll;

        max-height: 15rem;

        @screen lg {
            max-height: 10rem;
        }
    }

    & .dropdown-item {
        @apply h-12 w-full text-center  px-4;

        outline: none;

        &:hover {
            background: linear-gradient(128.5deg, rgba(248, 249, 253, 0.4) 12%, rgba(244, 247, 255, 0.4) 88%);
        }

        &:focus {
            background: linear-gradient(128.5deg, rgba(248, 249, 253) 12%, rgba(244, 247, 255) 88%);
        }

        &:not(:last-of-type) {
            @apply border-b;
        }

        @screen lg {
            @apply h-10 text-left px-3 whitespace-nowrap;
        }

        &.dropdown-item--selected {
            @apply font-bold;
        }
    }
}

.dropdown-list__input-header {
    @apply font-medium text-lg bg-white border border-wl-base-borders;
    @apply rounded-t-lg flex justify-between px-6 py-4 relative z-20;

    @screen lg {
        @apply hidden;
    }
}
</style>
