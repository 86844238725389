<template>
    <div class="search-input" :class="{ 'has-value': value?.length }">
        <label :for="`form-input-${name}`" class="search-input__label">{{ label }}</label>
        <div class="search-input__group" :class="{ error: validation ? validation.$error : false }">
            <input
                :id="`form-input-${name}`"
                :value="value"
                type="text"
                :name="name"
                :placeholder="placeholder"
                class="search-input__input"
                :class="{ 'has-error': error }"
                autocomplete="off"
                autocapitalize="off"
                spellcheck="false"
                @input="(event) => emit('update:value', (event.target as HTMLInputElement).value)"
            />
            <ErrorMessage v-if="error" :message="errorMessage" class="search-input__error-message" />
        </div>
    </div>
</template>
<script setup lang="ts">
import ErrorMessage from './Common/ErrorMessage.vue';

defineProps({
    name: { type: String, required: true, default: '' },
    label: { type: String, required: true, default: '' },
    tooltip: { type: String, required: false },
    placeholder: { type: String, required: true, default: 'Placeholder' },
    value: { type: String, required: false },
    prependIcon: { type: String, required: false },
    validation: { type: Object, required: false, default: null },
    error: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' },
});

const emit = defineEmits(['update:value']);
</script>

<style lang="postcss" scoped>
.search-input {
    &__group {
        @apply relative;
    }
    &__input {
        @apply w-full mb-0 px-3 rounded-lg border border-wl-base-soft-gray h-10 focus:outline-4 focus:outline-wl-brand-pr focus:outline placeholder:text-wl-base-mid-gray;
        &.has-error {
            border: 1px solid #ff0000;
            &:focus {
                @apply border-wl-brand-pr;
            }
        }
    }
    &__error-message {
        @apply absolute left-0 pr-2;
        top: calc(100% + 0.5rem) !important;
        width: 12.5rem;
    }
}
</style>
