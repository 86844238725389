<template>
    <div>
        <div
            v-show="modelValue"
            class="ts-back-drop"
            :class="{ 'ts-back-drop--hidden': hiddenBackdrop }"
            @click.stop.prevent="emit('update:modelValue', !modelValue)"
        />
        <slot />
    </div>
</template>

<script setup lang="ts">
import { useIsMobileView } from '~ui/composables/isMobileView';
import screens from '#tailwind-config/theme/screens';
const { isMobileView } = useIsMobileView(screens.md);

const { hiddenBackdrop, modelValue } = defineProps<{
    hiddenBackdrop?: boolean;
    modelValue?: boolean;
}>();

const emit = defineEmits(['update:modelValue']);

useHead({
    bodyAttrs: {
        class: computed(() => {
            if (modelValue && isMobileView.value) return 'body-overflow-hidden';
            return '';
        }),
    },
});
</script>

<style lang="postcss">
.ts-back-drop {
    @apply flex justify-center items-center fixed top-0 bottom-0 left-0 right-0 z-10;

    background-color: rgba(0, 0, 0, 0.25);
}

.ts-back-drop--hidden {
    @apply hidden;
}

body.body-overflow-hidden {
    overflow: hidden;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;
    touch-action: none;
}
</style>
