<template>
    <div class="airport-search" :class="{ 'airport-search--open': showDropdown }">
        <div class="airport-search__mobile-header">
            <button type="button" class="airport-search__mobile-icon-back" @click="closeDropdown">
                <NuxtIcon name="ri:arrow-left-s-line" size="20" />
            </button>
            <div>Choose your airport</div>
        </div>
        <label class="airport-search__label" :for="`${name}Input`">
            {{ label }}
        </label>
        <div v-on-click-outside="clickOutside" class="search-input" @focusin="openDropdown">
            <NuxtIcon
                v-if="icon"
                :name="`ri:${icon}`"
                class="input-icon-prepend"
                :class="{ 'rotate-45': icon === 'plane-fill' || icon === 'plane-line' }"
            />
            <!-- @vue-ignore -->
            <input
                :id="`${name}Input`"
                :value="inputValue"
                :name="name"
                :disabled="!isMounted"
                type="search"
                :placeholder="placeholder"
                autocomplete="off"
                :class="{ 'has-error': error }"
                @input="(e) => (inputValue = e.target.value)"
                @focus="(e) => (inputValue = '')"
            />
            <IconButton
                v-if="inputValue.length"
                icon-name="ri:close-line"
                class="airport-search__mobile-clear-search text-wl-base-dark-grey"
                aria-label="Clear search"
                @click.stop.prevent="clearSearch"
            />
            <ul v-show="showDropdownValue" class="input-dropdown">
                <template v-if="withCountries">
                    <template v-for="(item, index) in filteredSearch" :key="index">
                        <template v-if="item[0] && isCountry(item[0].country.slug)">
                            <li
                                v-for="(childItem, childIndex) in item"
                                :key="childIndex"
                                class="input-dropdown-item"
                                @click="selected = childItem.name"
                            >
                                <div class="input-dropdown-item__city">
                                    {{ childItem.name }} <span>({{ childItem.code }})</span>
                                </div>
                                <div class="input-dropdown-item__country">{{ item[0].country.name }}</div>
                            </li>
                        </template>
                    </template>
                </template>
                <template v-else>
                    <li
                        v-for="item in filteredSearch?.[0]"
                        :key="item.id"
                        class="input-dropdown-item input-dropdown-item--city"
                        @click="selected = item.name"
                    >
                        {{ item.name }}
                        <span>({{ item.code }})</span>
                    </li>
                </template>
            </ul>
            <ErrorMessage v-if="showError" :message="errorMessage" class="error-message" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components';

import { useIsMobileView } from '~ui/composables/isMobileView';
import screens from '#tailwind-config/theme/screens';

const { isMobileView } = useIsMobileView(screens.md);

import ErrorMessage from './Common/ErrorMessage.vue';
import IconButton from './Common/IconButton.vue';

const props: any = defineProps({
    name: { type: String, required: true, default: 'search' },
    placeholder: { type: String, required: false, default: '' },
    value: { type: Object, required: false, default: null },
    icon: { type: String, required: false, default: '' },
    items: { type: Array, required: false, default: null },
    country: { type: String, required: false, default: '' },
    error: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' },
    label: { type: String, default: 'Airport' },
    withCountries: { type: Boolean, default: false },
});

// Constant variable to define prop 'items'
const itemArray: any = ref(props.items);

// Constant variable to define prop 'value'
const inputValue: any = ref(props.value.name);

// Toggle to show/hide dropdown
const showDropdown = ref(false);

const isMounted = ref(false);

// Initialising emit events
const emit: any = defineEmits(['update:value']);

// Whenever new time is set, we want to update the variable, close dropdown and emit the data
// to parent for adding to store.
const selected: any = computed({
    get() {
        return props.value.name;
    },
    set(value) {
        let selected: any = value;

        if (props.withCountries) {
            props.items.forEach((itemSection: any) => {
                itemSection.forEach((item: any) => {
                    if (item.name === value) selected = item;
                });
            });
        } else {
            props.items.forEach((item: any) => {
                if (item.name === value) selected = item;
            });
        }

        inputValue.value = value;
        emit('update:value', selected);
        closeDropdown();
    },
});

// Get country name
const { locale } = useI18n();

const swapCountry = {
    'be-nl': 'be',
    'be-fr': 'be',
    'ca-fr': 'ca',
    'ch-fr': 'ch',
};

const mappedCountry = swapCountry[locale.value] || locale.value;
// Current country need to be first in search array
const sortedByCountryItems = computed(() => {
    if (!props.withCountries) return [props.items];

    const countryIndex = itemArray.value.map((el) => el[0].country.slug).indexOf(mappedCountry.toUpperCase());
    if (countryIndex === -1) {
        return itemArray.value;
    }

    const copy = [...itemArray.value];
    return [copy.splice(countryIndex, 1)[0], ...copy];
});

// Computed variable to get calculate filtered items
const filteredSearch: any = computed(() => {
    const filter = inputValue.value;

    return sortedByCountryItems?.value?.map((group: any) => {
        return group
            .filter((airport: any) => {
                const name = airport?.name?.toLowerCase() || '';
                const code = airport?.code?.toLowerCase() || '';
                const country = airport?.country?.name?.toLowerCase() || '';
                return (
                    name.includes(filter?.toLowerCase()) ||
                    code.includes(filter?.toLowerCase()) ||
                    country.includes(filter?.toLowerCase())
                );
            })
            .sort((airport1: any, airport2: any) => airport1.name.localeCompare(airport2.name));
    });
});

const showError = computed(() => props.error && !showDropdown.value);

// Event to close dropdown
const closeDropdown = () => {
    showDropdown.value = false;
    nextTick(() => (inputValue.value = selected.value));
};

// Event to open dropdown
const openDropdown = () => {
    showDropdown.value = true;
};

const clickOutside = () => {
    if (!isMobileView.value) closeDropdown();
};

const clearSearch = () => {
    inputValue.value = '';
};

function isCountry(country: string) {
    if (props.country) {
        if (country?.toLowerCase() === getLanguageForAPI(props.country.toLowerCase())) {
            return true;
        } else {
            return false;
        }
    } else {
        return true;
    }
}

onMounted(() => {
    isMounted.value = true;
});

const showDropdownValue = computed(() => {
    if (isMobileView.value) return showDropdown.value && inputValue.value.length > 0;
    return showDropdown.value;
});

useHead({
    bodyAttrs: {
        class: computed(() => {
            if (showDropdown.value && isMobileView.value) return 'body-overflow-hidden';
            return '';
        }),
    },
});
</script>

<style lang="postcss" scoped>
.airport-search {
    &--open {
        @media (max-width: theme('screens.md')) {
            @apply fixed md:relative inset-0 bg-white;
            z-index: 1000;
            .airport-search__mobile-header {
                @apply relative flex justify-center mb-1 px-6 pt-6 pb-4 border-b border-wl-base-borders text-wl-base-dark font-medium text-xl;
            }
            .airport-search__mobile-icon-back {
                @apply absolute left-6 text-wl-brand-pr;
            }
            .airport-search__mobile-clear-search.icon-button {
                @apply block md:hidden pl-4;
                span {
                    @apply block;
                }
            }
            .airport-search__label {
                @apply px-6 py-1;
            }
            .search-input {
                @apply px-6 pb-4 border-b border-wl-base-borders;
            }
        }
    }
    &__mobile-header,
    &__mobile-clear-search {
        @apply hidden;
    }
    &__label {
        @apply block mb-2 font-semibold text-wl-base-dark-grey;
    }
}

.input-dropdown {
    @apply absolute left-0 right-0 z-[99] top-[2.75rem] bg-white max-h-[80vh] md:max-h-[300px] overflow-y-auto md:border md:rounded-lg border-wl-base-borders mt-4 md:mt-0;
}

.input-dropdown-item {
    @apply flex gap-1 flex-col p-3 md:p-2 border-b border-[#e9daf0] text-wl-base-dark;
    &__city,
    &__country {
        @apply flex flex-row items-center justify-center md:justify-start;
    }
    &__country {
        @apply text-sm text-wl-base-dark-grey;
    }
}

.input-dropdown-heading {
    @apply font-semibold;
}

.input-dropdown-item span {
    @apply text-wl-base-dark-grey pl-1;
}

.input-dropdown-item:hover {
    @apply bg-primary text-white cursor-pointer;
    span,
    .input-dropdown-item__country {
        @apply text-white;
    }
}

.search-input {
    @apply relative flex items-center;
    input {
        @apply w-full mb-0 px-3 h-10 text-base rounded-lg border border-wl-base-soft-gray focus:outline-4 focus:outline-wl-brand-pr focus:outline text-[#282C4A];
        &.has-error {
            border: 1px solid #ff0000;
            &:focus {
                @apply border-wl-brand-pr;
            }
        }
    }
    .error-message {
        @apply absolute left-0;

        top: calc(100% + 0.5rem);
        width: auto !important;

        @screen lg {
            @apply left-0 right-auto;
        }
    }
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
}
</style>
