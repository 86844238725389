export const terminalsList = [
    // Gatwick
    { id: 95, name: 'North Terminal', airportId: 16 },
    { id: 96, name: 'South Terminal', airportId: 16 },
    // Manchester
    { id: 67, name: 'Terminal 1', airportId: 1 },
    { id: 68, name: 'Terminal 2', airportId: 1 },
    { id: 69, name: 'Terminal 3', airportId: 1 },
    // Heathrow
    { id: 97, name: 'Terminal 1', airportId: 7 },
    { id: 98, name: 'Terminal 2', airportId: 7 },
    { id: 99, name: 'Terminal 3', airportId: 7 },
    { id: 100, name: 'Terminal 4', airportId: 7 },
    { id: 101, name: 'Terminal 5', airportId: 7 },
];

export const TABS = {
    AIRPORT_PARKING: 'airport-parking',
    PORT_PARKING: 'port-parking',
    AIRPORT_LOUNGES: 'airport-lounges',
    HOTEL_AND_PARKING: 'hotel-and-parking',
    FASTTRACK: 'fasttrack',
} as const;

export const roomTypeList = [
    { name: 'Single', id: '1' },
    { name: 'Double', id: '2' },
    { name: 'Twin', id: '3' },
    { name: 'Triple (3 adults)', id: '4' },
    { name: 'Family of 3', id: '5' },
    { name: 'Family of 4', id: '6' },
    { name: 'Family of 5', id: '7' },
    { name: 'Family of 6', id: '1001' },
    { name: 'Executive Double', id: '1003' },
    { name: 'Honeymoon', id: '1004' },
    { name: 'Family of 7', id: '1005' },
    { name: 'Executive Single', id: '1008' },
    { name: 'Quad (4 adults)', id: '1009' },
    { name: 'Exec.Family 4', id: '1010' },
];
