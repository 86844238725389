<template>
    <input name="Itinerary.Campaign" :value="query.campaign ?? ''" type="hidden" />
    <input name="Quote_Itinerary_Location_" type="hidden" :value="tabbedSearchStore.getAllData.location.code" />
    <input name="Itinerary.LocationCode" :value="tabbedSearchStore.getAllData.location.code" type="hidden" />
    <input name="Quote.Itinerary.Dates.From.Date_local" :value="itineraryFromDateLocal" type="hidden" />
    <input name="Itinerary.Dates.From.Date" :value="itineraryFromDate" type="hidden" />
    <input name="Itinerary.Dates.From.Time" :value="tabbedSearchStore.getAllData.dates.start.time" type="hidden" />
    <input name="Itinerary.Dates.To.Date" :value="itineraryToDate" type="hidden" />
    <input name="Itinerary.Dates.To.Time" :value="tabbedSearchStore.getAllData.dates.end.time" type="hidden" />
    <input name="Itinerary.DiscountCode" :value="tabbedSearchStore.getAllData.discount" type="hidden" />
    <input name="Itinerary.VelocityMemberNumber" value="" type="hidden" />
</template>

<script setup lang="ts">
const route: any = useRoute();
const { query } = route;
import { useTabbedSearchStore } from '../../../_base/store/tabbedSearch';
const tabbedSearchStore = useTabbedSearchStore();

// Constant Variable for access to locale & translations
const { t, locales, locale } = useI18n();

const localeObj: any = Object.values(locales.value).find((item: any) => item.code === locale.value);

const itineraryToDate = computed(() =>
    tabbedSearchStore?.getAllData?.dates?.end?.date
        ? new Date(Date.parse(tabbedSearchStore?.getAllData?.dates?.end?.date)).toISOString().split('T')?.[0]
        : null
);
const itineraryFromDate = computed(() =>
    tabbedSearchStore?.getAllData?.dates?.start?.date
        ? new Date(tabbedSearchStore.getAllData.dates.start.date).toISOString().split('T')[0]
        : null
);
const itineraryFromDateLocal = computed(() =>
    tabbedSearchStore?.getAllData?.dates?.start?.date
        ? new Date(tabbedSearchStore.getAllData.dates.start.date).toLocaleDateString(localeObj.iso)
        : null
);
</script>

<style scoped></style>
