<template>
    <UiTabbedSearchboxHero
        :blok="blok"
        :airports="airports"
        :form-actions="formActions"
        :feefo-image-url="imageUrl"
        @submit-form="submitForm"
        @scroll-to-reviews="scrollToReviews"
        @set-active-tab="setActiveTab"
    >
        <template #legacyFields>
            <client-only>
                <component :is="legacyFields[activeTab]" />
            </client-only>
        </template>
    </UiTabbedSearchboxHero>
</template>

<script setup lang="ts">
const config: any = useRuntimeConfig();
const { locale, t } = useI18n();
import { useTabbedSearchStore } from '../../_base/store/tabbedSearch';
import { TABS } from '~ui/components/TabbedSearchbox/constants';

const activeTab = ref('airport-parking');
const setActiveTab = (tab) => (activeTab.value = tab);

// Legacy fields
import ParkingLegacyFields from '../components/Form/ParkingLegacyFields.vue';
import PortLegacyFields from '~/components/Form/PortLegacyFields.vue';
import HotelLegacyFields from '~/components/Form/HotelLegacyFields.vue';
const tabbedSearchStore = useTabbedSearchStore();

const legacyFields = {
    [TABS.AIRPORT_PARKING]: ParkingLegacyFields,
    [TABS.PORT_PARKING]: PortLegacyFields,
    [TABS.HOTEL_AND_PARKING]: HotelLegacyFields,
};

const { blok } = defineProps<{ blok: any }>();

const airportsDropdownList: any = await useAsyncStoryblok(`l4/global/dropdowns/airports`, {
    version: config?.public?.STORYBLOK_VERSION === 'published' ? 'published' : 'draft',
    language: getLanguageForLocale(locale.value),
    resolve_relations: ['AirportList.country'],
}).then((data) => {
    const airports = [];
    data.value?.content?.List.map((countryAirports) => {
        countryAirports.List.map((airport) => {
            airports.push({
                code: airport?.code,
                name: airport?.name,
                country: {
                    slug: countryAirports.country?.content?.code,
                    name: countryAirports.country?.content?.name,
                },
                id: airport?.airportId,
            });
        });
    });

    return airports.filter(
        (item, index, array) => index === array.findIndex((findItem) => findItem?.code === item?.code)
    );
});

const portsDropdownList: any = await useAsyncStoryblok(`l4/global/dropdowns/ports`, {
    version: config?.public?.STORYBLOK_VERSION === 'published' ? 'published' : 'draft',
    language: getLanguageForLocale(locale.value),
    resolve_relations: ['AirportList.country'],
}).then((data) => {
    const airports = [];
    data.value?.content?.List.map((countryAirports) => {
        countryAirports.List.map((airport) => {
            airports.push({
                code: airport.code,
                name: airport.name,
                country: {
                    slug: countryAirports.country?.content.code,
                    name: countryAirports.country?.content.name,
                },
            });
        });
    });

    return airports.filter(
        (item, index, array) => index === array.findIndex((findItem) => findItem.code === item.code)
    );
});

const filteredResults = computed(() =>
    activeTab.value === TABS.PORT_PARKING ? portsDropdownList : airportsDropdownList
);

const groupItemBy = (array: any[], property: string) => {
    const hash: { [key: string]: any } = {};
    const props = property.split('.');
    for (let i = 0; i < array.length; i++) {
        const key: string = props.reduce((acc, prop) => {
            return acc && acc[prop];
        }, array[i]);
        if (!hash[key]) hash[key] = [];
        hash[key].push(array[i]);
    }
    return hash;
};

// Re-arrange the areas to be a child of their countries
const groupedItems = computed(() =>
    Object.values(groupItemBy([...new Set(filteredResults.value)], 'country.slug')).sort((arr1, arr2) =>
        arr1?.[0]?.country?.name?.localeCompare(arr2?.[0]?.country?.name)
    )
);

const airports = computed(() => ({ airportList: groupedItems.value, withCountries: true }));

const submitForm = ({ formData, form }) => {
    form.target.submit();
};

// Form actions
const formActions = computed(() => ({
    [TABS.AIRPORT_PARKING]: tabbedSearchStore.getAllData.location.code
        ? `/${locale.value}/${t('pages.parking')}/${t('pages.search-results')}/${tabbedSearchStore.getAllData.location.code?.toLowerCase()}`
        : '',
    [TABS.PORT_PARKING]: tabbedSearchStore.getAllData.location.code
        ? `/${locale.value}/${t('pages.port')}/${t('pages.search-results')}/${tabbedSearchStore.getAllData.location.code?.toLowerCase()}`
        : '',
    [TABS.HOTEL_AND_PARKING]: tabbedSearchStore.getAllData.location.code
        ? `/${locale.value}/booking-quote/airporthotel-results`
        : '',
}));

// FEEFO STARS
const { imageUrl } = useFeefo();

function scrollToReviews() {
    const header = document.querySelector('header');
    const headerHeight = header?.getBoundingClientRect().height;
    const customerReviews = document.getElementById('customerReviews');
    const customerReviewsTop = customerReviews?.getBoundingClientRect().top;
    if (headerHeight && customerReviewsTop) {
        window.scrollTo({
            top: customerReviewsTop - headerHeight,
            behavior: 'smooth',
        });
    }
}
</script>

<style lang="postcss" scoped>
:deep(.num-selector__button) {
    @apply text-[#6b3084];
}

:deep(.button-primary),
:deep(.search-submit) {
    @apply bg-[#048802] text-white hover:bg-[#00AA00];
}

:deep(.tab-btn) {
    @apply text-[#6C3084];
    &.is-active {
        @apply bg-[#6C3084];
    }
}
</style>
