<template>
    <div class="time-picker" @click.stop>
        <div class="time-picker__input-header">
            <h3>
                {{ labelMobile || $t('components.dateTimePicker.time') }}
            </h3>
            <IconButton icon-name="ri:close-fill" aria-label="Close" @click.stop.prevent="emit('close')" />
        </div>
        <div v-if="labelDesktop" class="time-picker__desktop-label">
            {{ labelDesktop }}
        </div>
        <button id="GATimePickerScrollUp" class="scroll-btn" @click.prevent="scrollUp">
            <NuxtIcon name="ri:arrow-up-s-line" class="text-wl-base-mid-gray" />
        </button>
        <div class="scroll-area">
            <div ref="scrollArea" v-scroll-lock="scrollLock" class="overflow-scroll no-scrollbar scroll-content">
                <button
                    v-for="(time, index) in timeList"
                    :id="`GATimePickerSelectTime_${time.value.replace(':', '_')}`"
                    :key="index"
                    type="button"
                    :data-testid="`time-picker-item-${time.value}`"
                    :class="[
                        'time-item',
                        { 'time-item--disabled': time.$isDisabled },
                        { 'time-item--selected': checkIfTimeSelected(time) },
                    ]"
                    :disabled="time.$isDisabled"
                    @click.stop="selectTime(time)"
                    @keydown.enter.stop="selectTime(time)"
                >
                    {{ timeFormat === 'GB' ? time.name : time.value }}
                </button>
            </div>
        </div>
        <button id="GATimePickerScrollDown" class="scroll-btn" @click.prevent="scrollDown">
            <NuxtIcon name="ri:arrow-down-s-line" class="text-wl-base-mid-gray" />
        </button>
    </div>
</template>

<script setup lang="ts">
import { vScrollLock } from '@vueuse/components';

import {
    format,
    utcToZonedTime,
    startOfDay,
    isBefore,
    parseISO,
    zonedTimeToUtc,
    isAfter,
    endOfDay,
    isSameDay,
    add,
    DATE_TIME_FORMATS,
} from '../../date-utilities';
import IconButton from '../Common/IconButton.vue';

type ValueProp = {
    date: string;
    time: string;
    value?: string;
};

type HourObj = {
    name: string;
    value: string;
    $isDisabled: boolean;
};

const props = defineProps({
    scrollLock: {
        type: Boolean,
        default: false,
    },

    value: {
        type: String,
        default: '',
    },

    /** Available time slots depend on the date, we can't select a time in the past */
    date: {
        type: String,
        default: '',
    },

    /** Minimum time to start at */
    minTime: {
        type: [Date, String],
        default: '',
    },

    /** Selected airport timezone */
    timeZone: {
        type: String,
        default: 'Europe/London',
    },

    step: {
        type: Number,
        default: 60, // 1 hour
    },

    isMobilePopUp: {
        type: Boolean,
        default: false,
    },

    mobilePopUpTitle: {
        type: String,
        required: false,
        default: '',
    },

    labelMobile: {
        type: String,
        default: '',
    },

    labelDesktop: {
        type: String,
        default: '',
    },

    timeFormat: {
        type: String,
        default: 'GB',
    },

    isEntry: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['input', 'on-select', 'close']);

const timeList: HourObj[] = computed(() => {
    const hours = [];
    const timeZone = props.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { date, step } = props;

    let targetDate = parseISO(date);
    if (isNaN(targetDate.getTime())) {
        targetDate = add(new Date(), { days: 5 });
    }
    const zonedDateStart = zonedTimeToUtc(startOfDay(targetDate), timeZone);
    const zonedDateEnd = zonedTimeToUtc(endOfDay(targetDate), timeZone);

    const currentTime = new Date();
    const utcToLocalTime = utcToZonedTime(currentTime, timeZone);

    let timeSlot = zonedDateStart;

    if (!props.isEntry) {
        timeSlot = add(zonedDateStart, { hours: 1 });
    }

    while (!isAfter(timeSlot, zonedDateEnd)) {
        const localTimeSlot = utcToZonedTime(timeSlot, timeZone);
        const $isDisabled = isBefore(localTimeSlot, utcToLocalTime) || isBefore(localTimeSlot, props.minTime);
        hours.push({
            name: format(localTimeSlot, DATE_TIME_FORMATS.time),
            value: format(localTimeSlot, DATE_TIME_FORMATS.time_24hr),
            $isDisabled,
        });
        timeSlot = add(timeSlot, { minutes: step });
    }

    if (!props.isEntry) {
        const lastSlot = add(zonedDateEnd, { seconds: -59 });
        const localLastSlot = utcToZonedTime(lastSlot, timeZone);
        hours.push({
            name: format(localLastSlot, DATE_TIME_FORMATS.time),
            value: format(localLastSlot, DATE_TIME_FORMATS.time_24hr),
            $isDisabled: isBefore(localLastSlot, utcToLocalTime) || isBefore(localLastSlot, props.minTime),
        });
    }

    return hours;
});

const selectTime = (time: HourObj) => {
    if (time.$isDisabled) {
        return;
    }

    emit('input', time);
    emit('on-select', time);
};

const checkIfTimeSelected = (time: HourObj) => {
    return time.value === props.value;
};

const scrollArea = ref(null);
const getScrollArea = computed(() => {
    if (scrollArea.value instanceof HTMLDivElement) {
        return scrollArea.value;
    }
    return document.createElement('div');
});

const getTimeItemHeight = () => {
    return getScrollArea.value.firstElementChild instanceof HTMLButtonElement
        ? getScrollArea.value.firstElementChild.offsetHeight
        : 0;
};

const scrollUp = () => {
    getScrollArea.value.scrollTop -= getTimeItemHeight() * 4;
};

const scrollDown = () => {
    getScrollArea.value.scrollTop += getTimeItemHeight() * 4;
};
</script>

<style lang="postcss" scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.time-picker {
    @apply fixed w-full bottom-0 left-0 z-50;

    @media (max-width: 1023px) {
        max-height: 100%;
    }

    @screen md {
        border-top-left-radius: 32px;
        border-top-right-radius: 32px;
    }

    @screen lg {
        @apply absolute rounded-lg bottom-auto bg-white border border-wl-base-soft-gray;

        width: 90px;
    }
}

.scroll-area {
    @apply flex flex-col bg-white border-wl-base-borders border border-t-0 md:rounded-lg;
    max-height: 20rem;

    @screen lg {
        @apply border-none;
    }
}

.time-item {
    @apply w-full h-12 flex shrink-0 items-center justify-center lowercase;

    &:not(:nth-last-child(1)) {
        @apply border-wl-base-borders border-b;
    }

    &:hover {
        background: linear-gradient(128.5deg, rgba(248, 249, 253, 0.8) 12%, rgba(244, 247, 255, 0.8) 88%);
    }

    &:focus {
        background: linear-gradient(128.5deg, rgba(248, 249, 253) 12%, rgba(244, 247, 255) 88%);
    }

    @screen lg {
        @apply h-12 text-sm;
    }
}

.time-item--disabled {
    @apply text-gray-400;
}

button {
    @apply outline-none;
}

.time-picker__input-header {
    @apply font-medium text-lg bg-white border-t border-b border-wl-base-borders rounded-t-lg
      flex justify-between pl-6 pr-4 py-4;

    @screen md {
        @apply py-6 hidden;

        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
    }

    @screen lg {
        @apply hidden;
    }
}

.time-picker__desktop-label {
    @apply text-sm text-center pt-3 hidden md:block text-wl-base-mid-gray;
}

.scroll-btn {
    @apply h-6 w-full items-center justify-center hidden md:block;

    & svg {
        @apply text-wl-base-borders;
    }
}

.hide-on-mobile {
    @apply md:flex hidden;
}
</style>
