<template>
    <div
        class="tabbed-searchbox-extra__keysellingpoints"
        :class="{ 'tabbed-searchbox-extra__keysellingpoints--with-feefo': showFeefoReviews }"
    >
        <div v-for="point in keySellingPoints" :key="point._uid" class="tabbed-searchbox-extra__keysellingpoint">
            <NuxtIcon name="ri:check-fill" class="text-green-500" size="24px" />
            <div v-html="renderRichText(point.content)"></div>
        </div>
    </div>
    <div v-if="showFeefoReviews" class="tabbed-searchbox-extra__feefo">
        <button @click="emit('scrollToReviews')">
            <NuxtImg
                v-if="feefoImageUrl"
                :src="feefoImageUrl"
                title="See what our customers say about us"
                alt="Feefo logo"
                format="webp"
                loading="lazy"
                densities="x1"
            />
        </button>
    </div>
</template>

<script setup lang="ts">
const { showFeefoReviews, feefoImageUrl, keySellingPoints } = defineProps<{
    showFeefoReviews;
    feefoImageUrl;
    keySellingPoints;
}>();

const emit = defineEmits(['scrollToReviews']);
</script>

<style lang="postcss" scoped>
.tabbed-searchbox-extra {
    &__keysellingpoints {
        @apply flex flex-col md:flex-row md:items-center justify-center gap-2 lg:gap-20 text-white w-full mb-6 md:mb-0;
        &--with-feefo {
            @apply justify-start gap-2 md:gap-6;
        }
    }
    &__keysellingpoint {
        @apply flex items-center gap-2 font-bold text-white;
    }
    &__feefo {
        img {
            @apply md:w-60;
        }
    }
}
</style>
