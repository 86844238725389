<template>
    <div class="input-group">
        <label for="beforeFlight" class="input-radio">
            <input
                id="beforeFlight"
                :checked="!hotelReturn"
                type="radio"
                name="return"
                :value="false"
                :disabled="!isMounted"
                @change="$emit('update:hotelReturn', false)"
            />
            {{ $t('searchWidget.hotel-and-parking.hotelBeforeFlightLabel') }}
        </label>
        <label for="afterFlight" class="input-radio">
            <input
                id="afterFlight"
                :checked="hotelReturn"
                type="radio"
                name="return"
                :value="true"
                :disabled="!isMounted"
                @change="$emit('update:hotelReturn', true)"
            />
            {{ $t('searchWidget.hotel-and-parking.hotelAfterFlightLabel') }}
        </label>
    </div>
</template>

<script setup lang="ts">
defineProps({
    hotelReturn: {
        type: Boolean,
        default: true,
    },
});

const emit = defineEmits(['update:hotelReturn']);

const isMounted = ref(false);

onMounted(() => (isMounted.value = true));
</script>

<style lang="postcss" scoped>
.input-group .input-radio {
    @apply flex items-center mr-4 mb-4 cursor-pointer text-black;

    & input {
        @apply relative min-w-[0.75rem] max-w-[0.75rem] min-h-[0.75rem] max-h-[0.75rem] mb-0 mr-4 p-0 border-0 rounded-full bg-white;

        &:after {
            content: ' ';
            @apply absolute -left-1 -top-1 w-5 h-5 rounded-full border border-wl-base-soft-gray;
        }

        &:checked::before {
            content: ' ';
            @apply absolute left-0.5 top-0.5 w-2 h-2 rounded-full bg-purple-600;
        }
    }
}
</style>
