import { useTrafficSplitterStore } from '../../../../apps/_base/store/traffic-splitter';
import { useTabbedSearchStore } from '../../../../apps/_base/store/tabbedSearch';

// Type for runtime config
interface RuntimeConfig {
    MIGRATION_SERVICE_URL?: string;
    PROPEL_LOUNGES_URL?: string;
    PROPEL_PARKING_URL?: string;
    STORYBLOK_FOLDER?: string;
}

// Safely get runtime config
const getSafeRuntimeConfig = (): RuntimeConfig => {
    try {
        return useRuntimeConfig().public;
    } catch {
        return {};
    }
};

// Ref for migration variant
export const inMigratedVariant = ref(false);

// Safely check if migration is active
export const isMigrationActive = () => {
    const config = getSafeRuntimeConfig();
    const { MIGRATION_SERVICE_URL, PROPEL_LOUNGES_URL, PROPEL_PARKING_URL } = config;

    return !!(
        MIGRATION_SERVICE_URL &&
        PROPEL_PARKING_URL &&
        MIGRATION_SERVICE_URL !== '""' &&
        PROPEL_PARKING_URL !== '""'
    );
};

// Export migration variant check function
export const checkMigrationVariant = async (code = '', toDate, key = '_airportparking') => {
    const config = getSafeRuntimeConfig();
    const { STORYBLOK_FOLDER } = config;
    const sitePath = STORYBLOK_FOLDER === 'l4' ? 'looking4' : 'skyparksecure';

    const trafficSplitterStore = isMigrationActive() ? useTrafficSplitterStore() : null;

    if (!code || !toDate || !trafficSplitterStore) {
        inMigratedVariant.value = false;
        return { inMigratedVariant };
    }

    const CHECK_INTERVAL = 1000 * 60 * 5;

    const trafficDataTimestampFromStore = trafficSplitterStore.splitterData.timestamp;

    if (!trafficDataTimestampFromStore || Date.now() - trafficDataTimestampFromStore > CHECK_INTERVAL) {
        if (import.meta.client) {
            try {
                const config = getSafeRuntimeConfig();
                const { MIGRATION_SERVICE_URL } = config;

                // @ts-ignore
                const response = await $fetch<any>(`${MIGRATION_SERVICE_URL}/site/${sitePath}`);
                // @ts-ignore
                if (response && response.percentages) {
                    trafficSplitterStore.updateSplitterData({
                        percentages: response.percentages,
                        timestamp: Date.now(),
                    });
                }
            } catch (err) {
                console.error(err);
            }
        }
    }

    // Create key for airport to sync with migration service
    const trafficKey = `${code?.toLowerCase()}` + key;

    const percentagesFromPinia = trafficSplitterStore.splitterData.percentages;

    const isAirportInMigration = percentagesFromPinia[trafficKey];

    if (!isAirportInMigration) {
        inMigratedVariant.value = false;
    } else {
        const toDateFormatted = new Date(toDate).toISOString().slice(0, 10);

        const dayStartFrom = findDatePosition(percentagesFromPinia[trafficKey], toDateFormatted);
        const resultedPercentage = percentagesFromPinia[trafficKey][dayStartFrom].value;
        if (resultedPercentage === 0) {
            inMigratedVariant.value = false;
        } else if (resultedPercentage === 100) {
            inMigratedVariant.value = true;
        } else {
            inMigratedVariant.value = percentagesFromPinia[trafficKey][dayStartFrom].inMigratedVariant;
        }
    }

    return { inMigratedVariant };
};

// Export redirect function
export const redirectToParkingPropel = (propelProductSKU: string) => {
    const config = getSafeRuntimeConfig();
    const route = useRoute();
    const searchStore = useTabbedSearchStore();

    // Safely access store properties with fallback values
    const entryDate = formatDateToPropel(searchStore.data.dates?.start?.date || new Date());
    const entryTime = searchStore.data.dates?.start?.time ?? '12:00'; // Default time if not specified
    const exitDate = formatDateToPropel(searchStore.data.dates?.end?.date || new Date());
    const exitTime = searchStore.data.dates?.end?.time ?? '12:00'; // Default time if not specified
    // @ts-expect-error code is not in the store
    const code = (searchStore.data.location?.code || '').toUpperCase();
    const discount = searchStore.data.discount ? `&discount=${searchStore.data.discount}` : '';
    const product = propelProductSKU ? `&product=${propelProductSKU}` : '';
    const terminalId = searchStore.data.terminal.id || 'dontKnow';
    let groups = '%7B"adult"%3A1%7D'; // default 1 adult - {adult:1}
    if (searchStore.data.passengers.adult) {
        const passengers = searchStore.data.passengers;
        const passengersParams = {};
        Object.keys(passengers)
            .filter((key) => passengers[key])
            .forEach((key: string) => {
                if (key !== 'children_ages') {
                    passengersParams[key] = passengers[key];
                } else {
                    passengersParams[key] = passengers[key];
                }
            });
        groups = JSON.stringify(passengersParams);
    }

    const query = route.query;
    let marketingChannels = '';
    if ('utm_source' in query) {
        marketingChannels += `&utmSource=${query['utm_source']}`;
    }
    if ('utm_medium' in query) {
        marketingChannels += `&utmMedium=${query['utm_medium']}`;
    }
    if ('clickref' in query) {
        marketingChannels += `&clickref=${query['clickref']}`;
    }

    window.location.href = `${config.PROPEL_PARKING_URL}search/?entryDate=${entryDate}&entryTime=${entryTime}&exitDate=${exitDate}&exitTime=${exitTime}&airport=${code}&terminal=${terminalId}&groups=${groups}${discount}${product}${marketingChannels}`;
};

export const redirectToLoungePropel = (propelProductSKU: string) => {
    const config = getSafeRuntimeConfig();
    const route = useRoute();
    const searchStore = useTabbedSearchStore();

    // Safely access store properties with fallback values
    const entryDate = formatDateToPropel(searchStore.data.dates?.start?.date || new Date());
    const entryTime = searchStore.data.dates?.start?.time || '12:00'; // Default time if not specified
    // @ts-expect-error code is not in the store
    const code = (searchStore.data.location?.code || '').toUpperCase();
    const product = propelProductSKU ? `&product=${propelProductSKU}` : '';
    const terminalId = searchStore.data.terminal.id || 'dontKnow';
    let groups = '%7B"adult"%3A1%7D'; // default 1 adult - {adult:1}
    if (searchStore.data.passengers.adult) {
        const passengers = searchStore.data.passengers;
        const passengersParams = {};
        Object.keys(passengers)
            .filter((key) => passengers[key])
            .forEach((key: string) => {
                if (key !== 'children_ages') {
                    passengersParams[key] = passengers[key];
                } else {
                    passengersParams[key] = passengers[key];
                }
            });
        groups = JSON.stringify(passengersParams);
    }

    const query = route.query;
    let marketingChannels = '';
    if ('utm_source' in query) {
        marketingChannels += `&utmSource=${query['utm_source']}`;
    }
    if ('utm_medium' in query) {
        marketingChannels += `&utmMedium=${query['utm_medium']}`;
    }
    if ('clickref' in query) {
        marketingChannels += `&clickref=${query['clickref']}`;
    }

    window.location.href = `${config.PROPEL_LOUNGES_URL}search/?date=${entryDate}&time=${entryTime}&exitDate=&exitTime=&terminal=${terminalId}&airline=&swapped=false$&groups=${groups}&airport=${code}${product}${marketingChannels}`;
};

// Utility function to format date
const formatDateToPropel = (date: string | Date | undefined) => {
    // If no date is provided, use current date
    if (!date) return new Date().toISOString().slice(0, 10);

    // Ensure we have a valid Date object
    const validDate = date instanceof Date ? date : new Date(date);

    // Check if the date is valid
    if (isNaN(validDate.getTime())) {
        console.warn('Invalid date provided, using current date');
        return new Date().toISOString().slice(0, 10);
    }

    // Adjust for timezone offset to prevent potential RangeError
    const timezoneOffset = validDate.getTimezoneOffset();
    const adjustedDate = new Date(validDate.getTime() - timezoneOffset * 60000);

    return adjustedDate.toISOString().slice(0, 10);
};

// Utility function to find date position
const findDatePosition = (dateValues: any, inputDate: string) => {
    const datesCopy = { ...dateValues };
    // remove default value for sorting only dates
    delete datesCopy?.default;
    // for format "YYYY-MM-DD", the normal JS sort() function works correctly
    const datesSorted = Object.keys(datesCopy).sort();

    if (inputDate < datesSorted[0] || datesSorted.length === 0) {
        return 'default';
    }

    for (let i = 0; i < datesSorted.length - 1; i++) {
        if (inputDate >= datesSorted[i] && inputDate < datesSorted[i + 1]) {
            return datesSorted[i];
        }
    }

    return datesSorted[datesSorted.length - 1];
};
