<template>
    <div class="tabs">
        <button
            v-for="tab in tabs"
            :key="tab.id"
            class="tab-btn"
            :class="{ 'is-active': activeTab === tab.id }"
            @click="setActiveTab(tab.id)"
        >
            <NuxtIcon :name="tab.icon" class="tab-btn__icon" :class="{ 'rotate-180': tab.id === 'fasttrack' }" />
            <span class="tab-btn__text">{{ tab.name }}</span>
        </button>
    </div>
</template>

<script setup lang="ts">
const { activeTabs, activeTab } = defineProps<{
    activeTabs;
    activeTab;
}>();

const activeTabsIds = activeTabs?.map((tab) => tab.tabType);
const emit = defineEmits(['setActiveTab']);
const setActiveTab = (tab) => emit('setActiveTab', tab);

const tabsList = {
    'airport-parking': {
        name: 'Airport parking',
        id: 'airport-parking',
        icon: 'ri:parking-box-line',
    },
    fasttrack: {
        name: 'Fast Track',
        id: 'fasttrack',
        icon: 'ri:rewind-line',
    },
    'airport-lounges': {
        name: 'Airport Lounges',
        id: 'airport-lounges',
        icon: 'ri:sofa-line',
    },
    'hotel-and-parking': {
        name: 'Hotel & Parking',
        id: 'hotel-and-parking',
        icon: 'ri:hotel-line',
    },
    'port-parking': {
        name: 'Port Parking',
        id: 'port-parking',
        icon: 'ri:anchor-line',
    },
};

const tabs = activeTabsIds?.map((tabId) => tabsList[tabId]);
</script>

<style lang="postcss" scoped>
.tabs {
    @apply flex border-b border-wl-base-borders;
    .tab-btn {
        @apply flex-grow;
    }
}

.tab-btn {
    @apply flex flex-col items-center p-2 gap-2 bg-white;
    & + .tab-btn {
        @apply border-l border-wl-base-borders;
    }
    &:first-child {
        @apply rounded-tl-lg;
    }
    &:last-child {
        @apply rounded-tr-lg;
    }
    &.is-active {
        @apply text-white;
    }
    &__icon {
        @apply h-8 w-8;
    }
    &__text {
        @apply text-sm md:text-base font-bold;
    }
}
</style>
