<template>
    <div class="relative">
        <div
            class="dropdown__wrapper"
            :aria-label="`dropdown-wrapper--${label.toLowerCase()}`"
            tabindex="0"
            @click="onClickDropdownTarget"
            @keydown.enter="onClickDropdownTarget"
            @focus="emit('focus')"
        >
            <slot name="dropdown-target">
                <div :class="dropdownClasses">
                    <div :class="['dropdown-target__content']">
                        <span v-if="localValue" class="dropdown__value">
                            {{ localValue }}
                        </span>
                        <span v-else class="dropdown__placeholder">
                            {{ label }}
                        </span>
                    </div>
                    <!-- Icon arrow -->
                    <svg
                        v-if="!readonly"
                        class="field-label-icon field-label-icon--rotatable"
                        :class="{ 'rotate-180': localDropdownOpened }"
                        width="10"
                        height="10"
                        viewBox="0 0 256 256"
                        fill="none"
                    >
                        <polygon
                            points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093"
                            fill="#64687E"
                        />
                    </svg>
                </div>
            </slot>
            <div v-if="error">
                <ErrorMessage
                    v-if="!redErrorMsg"
                    :message="errorMessage"
                    :html="errorHtml"
                    class="dropdown__error-message"
                />
                <p v-else class="error-msg">
                    {{ errorHtml }}
                </p>
            </div>
        </div>
        <slot :dropdown-opened="dropdownOpened" :local-dropdown-opened="localDropdownOpened">
            <DropdownList
                v-model="localValue"
                :items="items"
                :dropdown-opened="dropdownOpened"
                :mobile-pop-up-title="label"
                :match-width="matchWidth"
                @close="localDropdownOpened = false"
            >
                <template #dropdown-item="{ item, index }">
                    <slot name="dropdown-item" v-bind="{ item, index }" />
                </template>
            </DropdownList>
        </slot>
    </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import DropdownList from './DropdownList.vue';
import ErrorMessage from './ErrorMessage.vue';

export type DropdownSingleItem = {
    name: string;
    id: string | number;
};

export type DropdownItems = DropdownSingleItem[];

const props = defineProps({
    dropdownOpened: {
        type: Boolean,
        default: false,
    },
    items: {
        type: Array as PropType<DropdownItems>,
        required: true,
    },
    modelValue: {
        type: Object,
        required: true,
    },
    error: {
        type: Boolean,
        default: false,
        required: true,
    },
    errorMessage: {
        type: String,
        default: '',
    },
    errorHtml: {
        type: String,
        default: '',
    },
    placeholder: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: '',
    },
    required: {
        type: Boolean,
        default: false,
    },
    readonly: {
        type: Boolean,
        default: false,
    },
    redErrorMsg: {
        type: Boolean,
        default: false,
    },
    matchWidth: {
        type: Boolean,
        default: false,
    },
    isSuccess: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits<{
    (e: 'update:dropdownOpened', value: any): void;
    (e: 'update:modelValue', value: any): void;
    (e: 'focus'): void;
}>();

const localDropdownOpened = computed({
    get(): boolean {
        return props.dropdownOpened;
    },
    set(val: boolean) {
        emit('update:dropdownOpened', val);
    },
});

const localValue = computed({
    get(): string {
        return props.modelValue?.name;
    },
    set(val: boolean) {
        emit('update:modelValue', val);
        localDropdownOpened.value = false;
    },
});

const dropdownClasses = computed(() => [
    'dropdown',
    {
        'dropdown--active': localDropdownOpened.value,
        'dropdown--targetable': !props.readonly,
        'dropdown--error': props.error,
        'dropdown--success': props.isSuccess,
    },
]);

const onClickDropdownTarget = () => {
    if (!props.readonly) {
        localDropdownOpened.value = !localDropdownOpened.value;
    }
};
</script>

<style scoped lang="postcss">
.dropdown__wrapper {
    @apply relative w-full;

    & > div:not(.dropdown) {
        @apply relative;

        top: 100%;
    }

    & .error-message.dropdown__error-message {
        @apply absolute;
        @apply left-0 pr-2;

        top: calc(100% + 0.5rem) !important;

        width: 12.5rem;
    }
}

.dropdown-target__prefix {
    @apply mr-1;
}

.dropdown {
    @apply h-10 flex items-center w-full px-3 rounded-lg
  relative border border-wl-base-soft-gray transition-colors duration-300;

    span {
        @apply truncate text-base;
    }

    .dropdown-target__content {
        @apply flex items-center justify-between w-full;
    }

    @screen lg {
        @apply h-10;
    }

    &.dropdown--targetable {
        @apply cursor-pointer;
    }

    &.dropdown--active {
        @apply outline outline-4 outline-wl-brand-pr;
        outline-style: solid;

        & .dropdown__placeholder,
        & .dropdown__value {
            transform: translateX(-1px);
        }
    }

    &.dropdown--error {
        @apply border-red-500;
    }
}

.dropdown__placeholder {
    @apply text-base text-wl-base-mid-gray;
}

.dropdown__value {
    @apply text-sm;

    @screen lg {
        @apply text-base;
    }
}

.field-label-icon {
    @apply mx-2 opacity-75;
}

.field-label-icon--rotatable {
    @apply inline transform;
}
</style>
