<template>
    <div class="customers-selector-wrapper">
        <span class="customers-selector-wrapper__label">
            <span>{{ label }}</span>
            <div class="customers-selector-wrapper__tooltip-wrapper">
                <NuxtIcon name="ri:question-line" size="20" class="customers-selector-wrapper__tooltip-icon" />
                <div class="customers-selector-wrapper__tooltip">
                    {{ $t('guestDetails.bundlesTooltip') }}
                </div>
            </div>
        </span>
        <Backdrop v-model="customersBackdrop" v-scroll-lock="customersBackdrop">
            <DropdownWrapper
                ref="selectCustomers"
                v-model:dropdown-opened="customersOpened"
                v-on-click-outside="() => (customersOpened = false)"
                :model-value="computedCustomers"
                required
                :error="error?.$error"
                :error-message="error?.$error"
                :label="$t('searchWidget.guests')"
                :items="[]"
                placeholder="Select"
            >
                <template #default="{ dropdownOpened }">
                    <CustomersSelector
                        v-show="dropdownOpened"
                        class="customer-dropdown"
                        :value="customers"
                        @close="() => (customersOpened = false)"
                        @update-customers="updateCustomersHandler"
                    />
                </template>
            </DropdownWrapper>
        </Backdrop>
    </div>
</template>

<script setup lang="ts">
import DropdownWrapper from '../Common/DropdownWrapper.vue';
import Backdrop from '../Common/Backdrop.vue';
import screens from '#tailwind-config/theme/screens';
import { useIsMobileView } from '~ui/composables/isMobileView';
import { vScrollLock, vOnClickOutside } from '@vueuse/components';

const CustomersSelector = defineAsyncComponent({
    loader: async () => await import('./CustomersSelector.vue'),
});

const { t } = useI18n();

const props = defineProps<{
    error?: any;
    customers: any;
    label: string;
    dropdownOpened: boolean;
}>();

const emit = defineEmits<{
    (e: 'update-customers', value: any): void;
    (e: 'update:dropdownOpened', value: boolean): void;
}>();

const { isMobileView } = useIsMobileView(screens.sm);

const customersOpened = computed({
    get(): boolean {
        return props.dropdownOpened;
    },
    set(val: boolean) {
        emit('update:dropdownOpened', val);
    },
});

const customersBackdrop = computed({
    get(): boolean {
        return isMobileView.value && customersOpened.value;
    },
    set(): void {
        customersOpened.value = false;
    },
});

const updateCustomersHandler = (value) => {
    customersOpened.value = false;
    emit('update-customers', value);
};

const computedCustomers = computed(() => {
    if (!props.customers) {
        return { id: '', name: '' };
    }

    const { adult, child } = props.customers;
    const label: any = [];

    const getAgeText = (key: 'adult' | 'child', count: number) => `x ${t(`customers.${key}`, count)}`;
    if (adult) {
        label.push(`${adult} ${getAgeText('adult', adult)}`);
    }
    if (child) {
        label.push(`${child} ${getAgeText('child', child)}`);
    }

    return label.length ? { id: 'id', name: label.join(', ') } : { name: '', id: '' };
});
</script>

<style lang="postcss" scoped>
.customers-selector-wrapper {
    &__label {
        @apply flex items-center mb-2 font-semibold text-wl-base-dark-grey;
    }

    &__tooltip-wrapper {
        @apply relative;
    }

    &__tooltip-icon {
        @apply text-wl-base-dark-grey ml-1 cursor-pointer block;
        &:hover + .customers-selector-wrapper__tooltip {
            @apply block;
        }
    }

    &__tooltip {
        @apply hidden bg-black absolute left-full bottom-full w-52 px-3 py-2 rounded-lg text-white text-xs;
    }
}
</style>
