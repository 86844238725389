<template>
    <input name="airportA" :value="tabbedSearchStore.getAllData.location.id" type="hidden" />
    <input name="hotelToParking" value="false" type="hidden" />
    <input name="category" value="airporthotel" type="hidden" />
    <input name="params" value="[]" type="hidden" />
    <input name="terminal" value="" type="hidden" />
    <input name="timeA" value="12:00" type="hidden" />
    <input name="timeB" value="12:00" type="hidden" />
    <input name="room1" :value="tabbedSearchStore.getAllData.rooms.id" type="hidden" />
    <!-- Update Storyblok -->
    <input name="agent" :value="tabbedSearchStore.getAllData.agentCode" type="hidden" />
    <input
        :name="tabbedSearchStore.getAllData.hotelReturn ? 'dateB' : 'dateA'"
        :value="itineraryFromDate"
        type="hidden"
    />
    <input
        :name="tabbedSearchStore.getAllData.hotelReturn ? 'dateA' : 'dateB'"
        :value="itineraryToDate"
        type="hidden"
    />

    <input name="promo" :value="tabbedSearchStore.getAllData.discount" type="hidden" />
</template>

<script setup lang="ts">
const route: any = useRoute();
import { useTabbedSearchStore } from '../../../_base/store/tabbedSearch';
const tabbedSearchStore = useTabbedSearchStore();

const itineraryToDate = computed(() =>
    tabbedSearchStore?.getAllData?.dates?.end?.date
        ? new Date(Date.parse(tabbedSearchStore?.getAllData?.dates?.end?.date)).toISOString().split('T')?.[0]
        : null
);
const itineraryFromDate = computed(() =>
    tabbedSearchStore?.getAllData?.dates?.start?.date
        ? new Date(tabbedSearchStore.getAllData.dates.start.date).toISOString().split('T')[0]
        : null
);
</script>
