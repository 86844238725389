<template>
    <div class="calendar-container" @click.stop>
        <div class="calendar-container__mobile-header">
            <h3>
                {{ label }}
            </h3>
            <IconButton icon-name="ri:close-fill" aria-label="Close" @click.stop.prevent="emit('close')" />
        </div>
        <div>
            <VDatePicker
                :id="`${name}-datepicker`"
                v-model="dateVal"
                tabindex="0"
                mode="date"
                expanded
                color="primary"
                :popover="false"
                :class="anchor"
                :columns="columnCount"
                :min-page="minPage"
                :min-date="new Date(minDate)"
                :max-date="new Date(new Date().setMonth(new Date().getMonth() + 12))"
                :locale="{
                    id: localeObj.iso,
                    firstDayOfWeek: 1,
                    masks: { weekdays: 'WW', input: 'WWW, DD/MM/YYYY' },
                }"
            >
            </VDatePicker>
        </div>
    </div>
</template>

<script setup lang="ts">
const VDatePicker = defineAsyncComponent({
    loader: async () => (await import('@angelblanco/v-calendar')).DatePicker,
});
import IconButton from '../Common/IconButton.vue';

import { format, DATE_TIME_FORMATS } from '../../date-utilities';

// Calendar Styles
import '@angelblanco/v-calendar/style.css';

const props: any = defineProps({
    name: { type: String, required: true },
    value: { type: [String, Object], required: false, default: '' },
    label: { type: String, required: false, default: '' },
    calendarHeading: { type: String, required: false, default: 'Please select a date' },
    placeholder: { type: String, required: false, default: 'Select Date' },
    datePickerTitle: { type: String, required: false, default: 'Please select a date' },
    tooltip: { type: String, required: false, default: '' },
    anchor: { type: String, required: false, default: 'left' },
    validation: { type: Object, required: false, default: null },
    minDate: { type: [String, Date], required: false, default: '' },
    allowSingleRange: { type: Boolean, required: false, default: false },
});

// Constant Variable for access to locale & translations
const { locales, locale } = useI18n();

onMounted(() => {
    setTimeout(() => document.getElementById(`${props.name}-datepicker`)?.focus(), 100);
});

const localeObj: any = Object.values(locales.value).find((item: any) => item.code === locale.value);
const columnCount = ref(2);
const minPage: object = {
    month: new Date(new Date().setMonth(new Date().getMonth())).getMonth(),
    year: new Date().getFullYear(),
};

// Constant variable for initialising emit events
const emit: any = defineEmits(['update:value', 'toggle-calendar', 'close']);

// Whenever new date is set, we want to update locale date and emit the data
// to parent where it will set the new minimum date when required.
// We then close dropdown as it is no longer needed.
const dateVal: any = computed({
    get(): any {
        return props.value;
    },
    set(value: any): any {
        if (!value) return;
        const formattedDate = format(value, DATE_TIME_FORMATS.year_month_day);
        emit('update:value', formattedDate);
        toggleCalendar(false);
        return value;
    },
});

const toggleCalendar = (val: boolean) => {
    setTimeout(() => emit('toggle-calendar', val), 200);
};

// Change column count from 1 (mobile) to 2 (desktop/tablet)
function onWidthChange() {
    if (768 < window.innerWidth) {
        columnCount.value = props.allowSingleRange ? 1 : 2;
    } else {
        columnCount.value = 1;
    }
}

if (!process.server) {
    onMounted(() => {
        // Initialize Column Count
        onWidthChange();

        // Watch for column count
        window.addEventListener('resize', onWidthChange);
    });
    onUnmounted(() => window.removeEventListener('resize', onWidthChange));
}
</script>

<style lang="postcss" scoped>
.calendar-container {
    @apply absolute top-12 z-10;

    &__mobile-header {
        @apply flex justify-between px-6 py-4 relative z-[1000] bg-white rounded-t-lg border-y border-wl-base-borders text-lg font-medium md:hidden;
    }

    @media (max-width: theme('screens.md')) {
        @apply fixed bottom-0 top-auto left-0 right-0;

        .calendar-background {
            @apply fixed top-0 left-0 right-0 bottom-0 bg-gray-900/50 z-[99];
        }

        .input-group-container {
            @apply hidden;
        }

        .close {
            @apply fixed block left-4 top-4 text-white;
        }

        & + :deep(.vc-popover-content-wrapper) {
            @apply !fixed !top-[5rem] !left-0 !right-0 !bottom-0 !transform bg-white z-[101];

            .vc-popover-content {
                @apply !mt-0 rounded-none shadow-none border-b-0;
            }

            .vc-container {
                @apply z-[101];
            }

            .calendar-header {
                @apply hidden;
            }

            .vc-pane-header-wrapper {
                @apply top-0;
            }

            .vc-weeks {
                @apply pt-4;
            }

            .vc-day,
            .vc-day-content,
            .vc-highlight {
                @apply min-w-full;
                min-height: calc(100vw / 8);
            }
        }

        :deep(.vc-bordered) {
            @apply border-none rounded-none;
        }

        :deep(.vc-container) {
            box-shadow: 0px -20px 50px -12px rgb(0 0 0 / 0.25);
        }
    }

    :deep(.vc-container) {
        @apply z-[99] flex flex-col min-w-full max-w-[100vw] shadow-md;
    }

    :deep(.vc-weeks) {
        @apply pb-4;
    }

    :deep(.vc-pane-container) {
        @apply flex flex-col-reverse;

        .calendar-header {
            @apply py-2 mx-4 bg-transparent font-semibold border-b border-gray-200 text-lg text-center;
        }

        .vc-day,
        .vc-day-content,
        .vc-highlight {
            @apply min-h-[40px] min-w-[40px] rounded-lg;
        }
        .vc-highlights + .vc-day-content {
            @apply font-normal;
        }
    }

    :deep(.vc-popover-content-wrapper) {
        @apply z-[99];
    }

    :deep(.vc-popover-content) {
        @apply overflow-hidden;

        &.direction-bottom {
            @apply md:mt-1;
        }
    }

    :deep(.vc-popover-caret) {
        @apply hidden;
    }

    :deep(.vc-header) {
        @apply py-3 h-auto;
    }

    :deep(.vc-pane) {
        @apply px-3;
    }

    :deep(.vc-pane + .vc-pane) {
        @apply border-l border-l-wl-base-borders;
    }

    :deep(.vc-highlight-content-solid) {
        @apply text-wl-base-dark bg-[#6b308429] !rounded-lg !font-bold;
    }

    :deep(.vc-day-content:hover) {
        background-color: #f8f9fd;
    }
}
</style>
