<template>
    <div class="date-time-picker__wrapper">
        <label class="date-time-picker__label" :for="name">
            {{ label }}
        </label>
        <div :class="datePickerClasses">
            <div
                v-on-click-outside="onDatePickerClickOutside"
                class="date-time-picker__date-picker"
                :class="`date-time-picker__date-picker--${calendarPosition}`"
                data-testid="date-time-picker"
                tabindex="0"
                @click="dateDropdownOpened ? closeDatePicker() : openDatePicker()"
                @keydown.enter="dateDropdownOpened ? closeDatePicker() : openDatePicker()"
                @focus="onFocusEvent('date')"
            >
                <NuxtIcon name="ri:calendar-todo-line" size="20" class="date-time-picker__icon" />
                <input v-if="date" :id="name" readonly class="date-time-picker__value" :value="formattedDate" />
                <span v-else class="date-time-picker__placeholder">
                    {{ $t('searchWidget.date') }}
                </span>

                <Backdrop v-show="dateDropdownOpened" v-model="hasBackdrop">
                    <client-only>
                        <DatePicker
                            :value="date"
                            :min-date="minDate"
                            :max-date="maxDate"
                            :label="label"
                            :name="name"
                            @update:value="onDateChange"
                            @close="closeCalendar"
                            @toggle-calendar="closeCalendar"
                    /></client-only>
                </Backdrop>
            </div>
        </div>
        <ErrorMessage v-if="displayError" :message="errorMessage" class="date-time-picker__error-message" />
    </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue';

import { vOnClickOutside } from '@vueuse/components';

import { useIsMobileView } from '~ui/composables/isMobileView';
import screens from '#tailwind-config/theme/screens';
const { isMobileView } = useIsMobileView(screens.md);

import { add, formatWithLocale, parseISO, DATE_TIME_FORMATS } from '../../date-utilities';
import Backdrop from '../Common/Backdrop.vue';
import ErrorMessage from '../Common/ErrorMessage.vue';
import DatePicker from './DatePicker.vue';

const props = defineProps({
    date: {
        type: String as PropType<string>,
        default: '',
    },

    minDate: {
        type: [String, Date] as PropType<string | Date>,
        default: () => new Date(),
    },

    maxDate: {
        type: [String, Date] as PropType<string | Date>,
        default: () => add(new Date(), { years: 1 }),
    },

    timeZone: {
        type: String,
        default: '',
    },

    error: {
        type: Boolean,
        default: false,
    },

    errorMessage: {
        type: [String, Array] as PropType<string | string[] | VueI18n.TranslateResult>,
        default: '',
    },

    dateFormat: {
        type: String,
        default: DATE_TIME_FORMATS.month_day_year,
    },

    label: {
        type: String,
        default: '',
    },

    calendarPosition: {
        type: String,
        default: 'left',
    },

    name: {
        type: String,
        default: '',
    },

    dropdownOpened: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['focus', 'date-change', 'blur', 'update:dropdownOpened']);

const dateDropdownOpened = computed({
    get(): boolean {
        return props.dropdownOpened;
    },
    set(val: boolean) {
        emit('update:dropdownOpened', val);
    },
});

const displayError = computed(() => {
    return props.error && !dateDropdownOpened.value;
});

const hasBackdrop = computed({
    get(): boolean {
        return dateDropdownOpened.value && isMobileView.value;
    },
    set(): void {
        closeDatePicker();
    },
});

const { locale, locales } = useI18n();
const localeObject = locales.value.filter((e) => e.code === locale.value)?.[0];

const formattedDate = computed(() => {
    return props.date
        ? formatWithLocale(parseISO(props.date), props.dateFormat, localeObject?.language || 'en-GB')
        : '';
});

const datePickerClasses = computed(() => [
    'date-time-picker',
    { 'date-time-picker--error': props.error },
    {
        'date-time-picker--active': dateDropdownOpened.value,
    },
]);

const onFocusEvent = (type: string) => {
    emit('focus');
};

const onDateChange = (date: string) => {
    closeDatePicker();
    emit('date-change', date);
};

const openDatePicker = () => {
    dateDropdownOpened.value = true;
    emit('focus');
};

const closeDatePicker = () => {
    dateDropdownOpened.value = false;
    emit('blur');
};

const onDatePickerClickOutside = () => {
    if (dateDropdownOpened.value) {
        dateDropdownOpened.value = false;
        return;
    }
    if (dateDropdownOpened.value) {
        closeDatePicker();
    }
};
const closeCalendar = () => {
    setTimeout(() => closeDatePicker(), 200);
};
</script>

<style lang="postcss" scoped>
.date-time-picker__wrapper {
    @apply relative w-full;
}

.date-time-picker {
    @apply relative flex rounded-lg bg-white h-10 border border-wl-base-soft-gray cursor-pointer transition-colors duration-300;

    @screen lg {
        @apply h-10;
    }

    &.date-time-picker--active {
        @apply outline outline-4 outline-wl-brand-pr;
    }

    &.date-time-picker--error {
        @apply border-red-500;
    }

    &__label {
        @apply block mb-2 font-semibold text-wl-base-dark-grey;
    }

    &__icon {
        @apply mr-2 text-wl-base-mid-gray -mb-0.5;
    }
}

.date-time-picker__date-picker,
.date-time-picker__time-picker {
    @apply relative px-3 flex items-center flex-grow;

    @screen lg {
        @apply pr-0;
    }
}

.date-time-picker__date-picker {
    @apply w-7/12;
}

.date-time-picker__time-picker {
    @apply w-5/12 pl-0;
}

.date-time-picker--active .date-time-picker__date-picker {
    padding-left: 11px;

    @screen lg {
        padding-left: 15px;
    }
}

.date-time-picker__placeholder {
    @apply text-base text-wl-base-mid-gray;
}

.date-time-picker__value {
    @apply text-base focus:outline-none w-full cursor-pointer;
}

.date-time-picker:deep(.time-picker) {
    @screen lg {
        top: -150px;
        left: 0;
    }
}

.date-time-picker__error-message {
    @apply absolute right-0;

    top: calc(100% + 0.5rem);
    width: auto !important;

    @screen lg {
        @apply left-0 right-auto;
    }
}

.date-time-picker__date-picker {
    &--left {
        :deep(.calendar-container) {
            @apply left-0;
        }
    }
    &--right {
        :deep(.calendar-container) {
            @apply right-0;
        }
    }
}
</style>
