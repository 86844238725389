export const useTabbedSearchStore = defineStore('tabbedSearchStore', {
    state: () => ({
        data: {
            return: false,
            location: {
                name: '',
                code: '',
                id: '',
            },
            startDestination: {
                code: '',
                name: '',
            },
            endDestination: {
                code: '',
                name: '',
            },
            dates: {
                start: {
                    date: '',
                    time: '',
                },
                end: {
                    date: '',
                    time: '',
                },
            },
            passengers: {
                total: 0,
                adult: 0,
                child: 0,
                infants: 0,
                children_ages: [],
            },
            discount: '',
            terminal: {
                id: '',
                name: '',
                airportId: '',
            },
            rooms: {
                name: '',
                id: '',
            },
            hotelReturn: false,
            agentCode: '',
        },
    }),
    getters: {
        getAllData: (state) => state.data,
    },
    actions: {
        updateQuoteForm(value: any) {
            this.data = value;
        },

        updateLocation(value: any) {
            this.data.location.code = value.code;
            this.data.location.id = value.id;
            this.data.location.name = value.name;
        },

        updateQuoteFormReturn(value: boolean) {
            this.data.return = value;
        },

        updateQuoteFormStartDestination(destination) {
            this.data.startDestination = destination;
        },

        updateQuoteFormEndDestination(destination) {
            this.data.endDestination = destination;
        },

        updateStartDates(date: string, time: string) {
            this.data.dates.start.date = date;
            this.data.dates.start.time = time;
        },

        updateEndDates(date: string, time: string) {
            this.data.dates.end.date = date;
            this.data.dates.end.time = time;
        },

        updateQuoteFormPassengers(passengers: any) {
            this.data.passengers = {
                adult: passengers.adult,
                child: passengers.child,
                infants: passengers.infants || 0,
                total: passengers.adult + passengers.children + passengers.infants,
                children_ages: passengers.children_ages,
            };
        },

        updateDiscount(discount: string) {
            this.data.discount = discount;
        },

        updateTerminal(terminal) {
            this.data.terminal = terminal;
        },
        updateRooms(rooms: any) {
            this.data.rooms = rooms;
        },
        updateHotelReturn(hotelReturn: boolean) {
            this.data.hotelReturn = hotelReturn;
        },
    },

    persist: true,
});
