import {
    format,
    formatISO,
    add,
    sub,
    parseISO,
    isBefore,
    isAfter,
    isValid,
    isEqual,
    isSameDay,
    isDate,
    startOfDay,
    startOfToday,
    addMinutes,
    endOfDay,
    differenceInMilliseconds,
    differenceInSeconds,
    differenceInMinutes,
    differenceInHours,
    differenceInDays,
    getHours,
    parse,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { es, enUS, enGB, fr, it, de, pl, pt, nl, enAU } from 'date-fns/locale';

const DATE_TIME_FORMATS = {
    year_month_day: 'yyyy-MM-dd',
    weekday_month_day: 'EEE, MMM dd',
    weekday_month_day_year: 'EEE, MMM dd, yyyy',
    weekday_month_day_year_am_pm_lower_case: 'EEE, MMM dd, yyyy - hh:mmaaa',
    weekday_month_day_year_time_24hr_lower_case: 'EEE, MMM dd, yyyy - HH:mm',
    month_day_year: 'MMM dd, yyyy',
    year_month_day_time: 'yyyy-MM-dd HH:mm',
    year_month_day_time_am_pm: 'yyyy-MM-dd HH:mm aa',
    time_24hr: 'HH:mm',
    time: 'hh:mmaa',
    time_lower_case: 'hh:mmaaa',
    day_month_year: 'dd MMM, yyyy',
    day_month_year_in_numbers: 'dd-MM-yyyy',
    day_month_year_day_time_am_pm: 'dd MMM yyyy, hh:mmaa',
    day_month_year_day_time_am_pm_lower_case: 'dd MMM yyyy, hh:mmaaa',
    full_month_day_year: 'MMMM dd, yyyy',
    full_day_month_year: 'dd MMMM, yyyy',
    month_day: 'MMM dd',
    day_month_year_slash: 'dd/MM/yy',
    year_month_day_utc: "yyyy-MM-dd'T'HH:mm:ss'Z'",
} as const;

const Locales = {
    'es-ES': es,
    'en-US': enUS,
    'en-GB': enGB,
    'fr-FR': fr,
    'it-IT': it,
    'de-DE': de,
    'pl-PL': pl,
    'pt-PT': pt,
    'nl-NL': nl,
    'en-AU': enAU,
} as const;

/** Returns the local time zone */
function guessTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

type Locale = keyof typeof Locales;

function formatWithLocale(date: Date | number, str: string, locale: string): string {
    return format(date, str, { locale: Locales[locale as Locale] });
}

function convertTimeStringToObject(timeString: string) {
    const [hours, minutes] = timeString.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const convertedHours = hours % 12 || 12;
    function num2text(num: number | string) {
        return String(num).substring(0, 2).padStart(2, '0');
    }
    return {
        name: `${num2text(convertedHours)}:${num2text(minutes)}${period}`,
        value: `${num2text(hours)}:${num2text(minutes)}`,
    };
}

export {
    format,
    formatWithLocale,
    formatISO,
    add,
    sub,
    parseISO,
    utcToZonedTime,
    isValid,
    isBefore,
    isAfter,
    isEqual,
    startOfDay,
    startOfToday,
    addMinutes,
    endOfDay,
    isSameDay,
    differenceInMilliseconds,
    differenceInSeconds,
    differenceInMinutes,
    differenceInHours,
    differenceInDays,
    getHours,
    isDate,
    guessTimezone,
    zonedTimeToUtc,
    DATE_TIME_FORMATS,
    convertTimeStringToObject,
    parse,
};
